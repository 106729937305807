/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}
.dropbtn {
    /* background-color: #4CAF50; */
    color: #324963bd;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    
  }

  .dropbtn {
    /* background-color: #4CAF50; */
    color: #324963bd;
    padding: 16px;
    font-size: 16px;
    border: none;
    /* cursor: pointer; */
    font-weight: 600;
    
  }
  @media screen and (max-width: 992px) {
    .dropbtn{
        color: black;
        padding: 16px;
        font-size: 18px;
        border: none;
        cursor: pointer;
        font-weight: 600;
    }
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    min-width: 200px;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    /* background-color: #3e8e41; */
  }

  video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .video-wrapper {
    border: 2px solid #000;
    width: 400px;
    height: 200px;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header{
    position: relative;
    color: white;
    text-shadow: 1px 1px 8px rgba(0,0,0,0.6);
  }